<template>
  <div class="w3-left-align">
    <i class="fa fa-inbox flow-main-color w3-xlarge w3-margin-right"></i>
    <ContentHeader v-bind:title="$t('addNewDomain')" />
    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <Column>
        <Row>
          <div class="grid-component">
            <StandardTextBox
              :np-name-error="npNameError"
              :text="newDomain.name"
              :label-name="$t('name')"
              :placeholder="$t('name')"
              @update-text="args => (newDomain.name = args)"
            />
          </div>
        </Row>

        <Row>
          <div class="grid-component">
            <label class="w3-left flow-main-color flow-padding-bottom">{{
              $t("domainType")
            }}</label>
            <div class="w3-col w-100pc zero flow-margin-right sort-select">
              <StandardSelect
                class=" w3-border w3-round-medium"
                type="text"
                :items="types"
                :valueKey="'name'"
                :valueKeyIndex="'id'"
                :callback="setSelectedType"
                :preSelectedItem="newDomain.type"
              />
            </div>
          </div>
        </Row>

        <Row>
          <div class="grid-component">
            <label class="w3-left flow-main-color flow-padding-bottom">{{
              $t("domain-message")
            }}</label>
            <StandardSelect
              :callback="setSelectedNotificationText"
              :items="standardTexts"
              :valueKey="'value'"
              :valueKeyIndex="'key'"
              class="w3-border w3-round-medium"
              type="text"
            />
          </div>

          <div class="grid-component">
            <StandardTextBox
              :np-name-error="showMinutesError ? minutesError : ''"
              :text="newDomain.closingTimeMinutes"
              :label-name="$t('closing-message-threshold')"
              :placeholder="$t('minutes')"
              :is-number="true"
              @update-text="args => (newDomain.closingTimeMinutes = args)"
            />
          </div>
        </Row>
      </Column>

      <div class="zero-bottom zero-top">
        <SaveButton
          :function-executed="createExecuted"
          :on-add="
            () => {
              addNewDomain();
            }
          "
        />

        <BackButton :on-clicked="() => sideFormStatusEvent('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import BackButton from "@/components/common/buttons/BackButton.vue";
import { mapActions } from "vuex";
import SaveButton from "@/components/common/buttons/SaveButton.vue";
import StandardTextBox from "@/components/common/StandardTextBox.vue";
import Column from "@/components/common/grid/Column.vue";
import Row from "@/components/common/grid/Row.vue";
import StandardSelect from "@/components/views/opening-hours/StandardSelect.vue";

export default {
  name: "NewDomain",
  components: {
    StandardSelect,
    Row,
    Column,
    StandardTextBox,
    SaveButton,
    BackButton,
    ContentHeader,
  },
  emits: ["addedNewDomain"],
  props: ["types", "standardTexts"],
  data() {
    return {
      newDomain: {},
      npNameError: undefined,
      npMinutesError: undefined,
      showMinutesError: false,
      minutesError: "",
      createExecuted: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      sideFormStatusEvent: "eventHandlers/sideFormStatusEvent"
    }),
    checkClosingTimeMessageAndMinutes() {
      this.showMinutesError = false;
      this.minutesError = "";
      let minutes = this.newDomain.closingTimeMinutes;
      let textKey = this.newDomain.notificationTextKey;

      if (minutes && (minutes > 180 || minutes < 0)) {
        this.showMinutesError = true;
        this.minutesError = this.$t("INVALID_MINUTES");
      } else if (minutes && minutes > 0 && !textKey) {
        this.showMinutesError = true;
        this.minutesError = this.$t("INVALID_NOTIFICATION_TEXT");
      } else if (textKey && (!minutes || minutes === 0)) {
        this.showMinutesError = true;
        this.minutesError = this.$t("INVALID_CLOSING_TIME_MINUTES");
      }
    },
    addNewDomain() {
      this.npNameError = undefined;
      this.npMinutesError = undefined;

      if (!this.newDomain.name || !this.newDomain.name.trim()) {
        this.npNameError = this.$t("INVALID_NAME");
      }

      this.checkClosingTimeMessageAndMinutes();

      if (this.npNameError || this.showMinutesError) return;

      this.createExecuted = true;

      this.$restClient.domains
        .add(this.newDomain)
        .then(response => {
          // eslint-disable-next-line no-unused-vars
          console.log(response);
          this.$notify.notifySuccess({
            title: this.$t("success"),
            message: this.$t("added-success")
          });
          this.$emit("addedNewDomain");
          this.sideFormStatusEvent("close");
        })
        .catch(err => {
          this.createExecuted = false;
          if (err && err.response.status === 400)
            this.$notify.notifyError({
              title: this.$t("failed"),
              message: this.$t("domain-name-unique")
            });
          else this.$notify.notifyError({ title: this.$t("failed") });
        });
    },

    setSelectedType(option) {
      this.newDomain.type = option;
    },
    setSelectedNotificationText(item) {
      if (item) {
        this.newDomain.notificationText = item;
        this.newDomain.notificationTextKey = item.key;
      }
    }
  }
};
</script>
