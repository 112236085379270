<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('opening-hours')" />
        <!--        view for + icon-->
        <div class="w3-right w3-margin-right flow-margin-top">
          <i
            class="fa fa-plus flow-main-color w3-xlarge cursor-pointer w3-margin-right"
            @click="sideBarStatusEvent('create')"
          ></i>
        </div>
        <!--        end view for + icon-->
      </div>
    </div>

    <div class="content-sidebars sidebars-70-30">
      <!-- defines width of main overview and sidebar in % - here -> 70% main, 30% sidebar-->
      <OpeningHourSideBar v-if="openSideBar()" :context="onSideBarStatus">
        <template #create
          ><CreateOpeningHourSideBar
            :domain-id="getFirstDomainId"
            :fetched-regimes="regimes"
            @success="onSuccess"
            @failure="onFailed"
        /></template>
        <template #update
          ><UpdateOpeningHourSideBar @success="onSuccess" @failure="onFailed"
        /></template>
      </OpeningHourSideBar>
      <!-- component to open sidebar and create or update a new record -->
      <div class="w3-container w3-margin-bottom zero">
        <loading v-bind:dspin="openingHourContentLoading"></loading>
      </div>
      <StandardAppTable
        v-show="!openingHourContentLoading"
        :is-clickable="true"
        :no-content="$t('noOpeningHours')"
        :move-table-left="openSideBar()"
        :column-definitions="columnDefinitions"
        :data="getOpeningHoursWithTranslatedDaysAndSorted"
        @openSideBar="sideBarStatusEvent('update')"
        @selectedRecord="openingHour => selectedOpeningHour(openingHour)"
      />
    </div>
  </div>
</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import OpeningHourSideBar from "@/components/views/opening-hours/OpeningHourSideBar.vue";
import CreateOpeningHourSideBar from "@/components/views/opening-hours/CreateOpeningHourSideBar.vue";
import UpdateOpeningHourSideBar from "@/components/views/opening-hours/UpdateOpeningHourSideBar.vue";
import { mapActions, mapGetters } from "vuex";
import StandardAppTable from "@/components/common/StandardTable.vue";
import Loading from "@/components/common/Loading.vue";

/**
 * This screen shows the domainTimings as openingHours. It obfuscates the domains and only shows it being tied to a regime.
 * <p>
 *   When you add, update or delete an openingHour, the backend will update the domainTimings for every domain in a site.
 *   <p>
 *     This component first fetches all the domains and then uses the id of the first domain in the list to show
 *     the openingHours (domainTimings) per regime.
 */
export default {
  name: "OpeningHours",
  components: {
    Loading,
    StandardAppTable,
    UpdateOpeningHourSideBar,
    CreateOpeningHourSideBar,
    OpeningHourSideBar,
    ContentHeader
  },
  computed: {
    ...mapGetters({
      onSideBarStatus: "eventHandlers/onOpeningHourSideBarStatus"
    }),
    getOpeningHoursWithTranslatedDaysAndSorted() {
      if (this.openingHours === undefined || this.openingHours.length === 0) return [];
      let oh = this.openingHours.map(openingHour => ({
        id: openingHour.id,
        day: { key: openingHour.day, value: this.$t(openingHour.day) },
        startTime: openingHour.startTime.slice(0, -3),
        endTime: openingHour.endTime.slice(0, -3),
        regimeId: openingHour.regimeId,
        regimeName: openingHour.regimeName
      }));
      this.sortList("day", "regimeName", "startTime", "endTime", oh);
      return oh;
    },
    getFirstDomainId() {
      if (this.domains.length === 0) return null;
      return this.domains[0].id;
    }
  },
  data() {
    return {
      columnDefinitions: [
        { key: "day", label: this.$t("day"), dataKey: "day.value" },
        { key: "regime", label: this.$t("regime"), dataKey: "regimeName" },
        {
          key: "starttime",
          label: this.$t('start-time') + " - " + this.$t('end-time'),
          dataKey: "startTime--endTime",
          separator: " - "
        }
      ],
      /**
       * Needed to sort the days in chronological order.
       */
      daysWithNumber: {
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6,
        SUNDAY: 7
      },
      openingHourContentLoading: true,
      openingHours: [],
      domains: [],
      regimes: []
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      sideBarStatusEvent: "eventHandlers/openingHourSideBarStatusEvent",
      selectOpeningHourEvent: "eventHandlers/selectOpeningHourEvent"
    }),
    onSuccess() {
      this.$notify.notifySuccess({
        title: this.$t("success")
      });
      this.fetchOpeningHours();
      this.sideBarStatusEvent('close');
    },
    onFailed(err) {
      let message = "";
      if (err.response.status === 500) message = err.response.status;
      else if (err.response.data.message === undefined) message = this.$t("FAILED_TO_UPDATE");
      else message = this.$t(err.response.data.message);
      this.$notify.notifyError({
        title: this.$t("failed"),
        message: message
      });
      this.fetchOpeningHours();
    },
    fetchOpeningHours() {
      if (this.openingHourContentLoading === false)
        this.openingHourContentLoading = true;

      this.$restClient.domainTimings
        .getDomainTimings(this.getFirstDomainId)
        .then(response => {
          const data = response.data;
          let domainTimingData;
          if (data.length > 0)
            domainTimingData = JSON.parse(JSON.stringify(data));

          this.openingHours = domainTimingData;
          this.openingHourContentLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.$notify.notifyError({
            title: this.$t("failed")
          });
          this.openingHourContentLoading = false;
        });
    },
    fetchDomains() {
      this.$restClient.domains
        .all()
        .then(response => {
          const data = response.data;
          let domains;
          if (data.length > 0) domains = JSON.parse(JSON.stringify(data));
          this.domains = domains;
        })
        .catch(err => {
          console.log(err);
          this.$notify.notifyError({
            title: this.$t("failed")
          });
        });
    },
    fetchRegimes() {
      this.$restClient.regimes
        .all()
        .then(response => {
          const data = response.data;
          let regimes;
          if (data.length > 0) regimes = JSON.parse(JSON.stringify(data));
          this.regimes = regimes;
        })
        .catch(err => {
          console.log(err);
          this.$notify.notifyError({
            title: this.$t("failed")
          });
        });
    },
    openSideBar() {
      return (
        this.onSideBarStatus === "create" || this.onSideBarStatus === "update"
      );
    },
    selectedOpeningHour(openingHour) {
      openingHour.unique = this.isOpeningHourUniqueByRegimeAndDay(openingHour);
      this.selectOpeningHourEvent(openingHour);
      this.sideBarStatusEvent("update");
    },
    /**
     * An openingHour that is unique by regime and day cannot get deleted, so we need to determine if a selected openingHour is unique or not.
     * @param openingHour
     * @returns {boolean}
     */
    isOpeningHourUniqueByRegimeAndDay(openingHour) {
      const filteredOpeningHours = this.openingHours.filter(oh => {
        return (
          oh.regimeId === openingHour.regimeId && oh.day === openingHour.day.key
        );
      });
      return filteredOpeningHours.length === 1;
    },
    /**
     * Sorts on day, then on regime, then on startTime and then on endTime
     * @param sortBy1 day
     * @param sortBy2 regime
     * @param sortBy3 startTime
     * @param sortBy4 endTime
     * @param oh openingHour
     */
    sortList(sortBy1, sortBy2, sortBy3, sortBy4, oh) {
      oh.sort((x, y) => {
        if (
          this.daysWithNumber[x["day"].key] < this.daysWithNumber[y["day"].key]
        )
          return -1;
        if (
          this.daysWithNumber[x["day"].key] > this.daysWithNumber[y["day"].key]
        )
          return 1;
        if (sortBy2 !== undefined && x[sortBy2] < y[sortBy2]) {
          return -1;
        }
        if (sortBy2 !== undefined && x[sortBy2] > y[sortBy2]) {
          return 1;
        }
        if (sortBy3 !== undefined && x[sortBy3] < y[sortBy3]) {
          return -1;
        }
        if (sortBy3 !== undefined && x[sortBy3] > y[sortBy3]) {
          return 1;
        }
        if (sortBy4 !== undefined && x[sortBy4] < y[sortBy4]) {
          return -1;
        }
        if (sortBy4 !== undefined && x[sortBy4] > y[sortBy4]) {
          return 1;
        }
        else return 1;
      });
    }
  },
  created() {
    this.sideBarStatusEvent("close");
    this.fetchDomains();
    this.fetchRegimes();
  },
  watch: {
    /**
     * The fetchOpeningHours method waits until the domains are fetched.
     * @param newValue
     */
    domains(newValue) {
      if (newValue) this.fetchOpeningHours();
    }
  }
};
</script>
