<template>
  <div class="w3-left-align">
    <i class="fa fa-notes-medical flow-main-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('addNewPersonAppointment')"></content-header>
    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">
        <div class="w3-container zero-top zero-left">

          <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("firstName") }}</label>
            <input class="w3-input w3-border w3-round-medium" type="text"
                   v-model="newVisit.firstname"
                   v-bind:placeholder="$t('firstName')"/>
            <span class="w3-text-red">{{ npFNameError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("lastName") }}</label>
            <input class="w3-input w3-border w3-round-medium" type="text"
                   v-model="newVisit.lastname"
                   v-bind:placeholder="$t('lastName')"/>
            <span class="w3-text-red">{{ npLNameError }}</span>
          </p>
        </div>

        <div class="w3-container zero-top zero-left">
          <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("domain") }}</label>
            <span class="w3-col w-100pc zero flow-margin-right sort-select">
                    <flow-select class=" w3-border w3-round-medium" type="text"
                                 :items="domainTypes"
                                 :valueKey="'name'"
                                 :valueKeyIndex="'id'"
                                 :callback="setSelectedDomain"
                                 :preSelectedItem="newVisit.domainType">
                    </flow-select>
                </span>
          </p>


          <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("languages") }}</label>
            <span class="w3-col w-100pc zero flow-margin-right sort-select">
                    <flow-select class=" w3-border w3-round-medium" type="text"
                                 :items="languages"
                                 :valueKey="'name'"
                                 :valueKeyIndex="'id'"
                                 :callback="setSelectedLanguage"
                                 :preSelectedItem="newVisit.language">
                    </flow-select>
                </span>
          </p>
        </div>

        <div class="w3-container zero-top zero-left">

          <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("contact") }}</label>
            <span class="w3-col w-100pc zero flow-margin-right sort-select">
                    <flow-select class=" w3-border w3-round-medium" type="text"
                                 :items="contacts"
                                 :valueKey="'name'"
                                 :valueKeyIndex="'id'"
                                 :callback="setSelectedContact"
                                 :preSelectedItem="newVisit.contact">
                    </flow-select>
            </span>
          </p>

          <p class="flow-padding-bottom zero-top w-45pc w3-right w3-show-inline-block">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("time") }}</label><br>
            <input class="w3-input w-80pc w3-show-inline-block time-picker-trigger" type="text"
                   v-bind:placeholder="timeNow()"
                   v-bind:class="timeError ? 'error' : ''"
                   @click="openTimePicker = !openTimePicker"
                   v-bind:value="newVisit.time.val"/>

            <i class="fa fa-clock w3-right w3-large flow-main-color flow-padding-left flow-padding-top cursor-pointer w3-ripple time-picker-trigger"
               @click="openTimePicker = !openTimePicker"
               :title="$t('openTimePicker')"
               style="padding-top: 10px"></i>
            <span class="w3-text-red"> {{ timeError }} </span>
          </p>
          <p class="flow-form-inline-input time-picker-container" v-if="openTimePicker">
            <time-picker
                         class="flow-card w3-animate-opacity"
                         :preSelected="newVisit.time"
                         :onChange="onChange"
                         :onClose="()=>{openTimePicker = false;}">
            </time-picker>
          </p>


        </div>

        <div class="w3-container zero-top zero-left">
          <p class="flow-padding-bottom zero-top flow-flex-col w3-left w-100pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("visitorComment") }}</label>
            <textarea class="w3-input w3-border w3-round-medium" name="textarea" maxlength="500"
                   v-model="newVisit.comment"
                   v-bind:placeholder="$t('visitorComment')"
                   style="resize: none;">
            </textarea>
          </p>
        </div>


      </div>

      <div class="zero-bottom zero-top">
        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="addNewVisit" :disabled="!saveVisitEnabled">
          <i class="fa fa-save flow-green-active-color">
            <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top"
                @click.prevent="onClose">
          <i class="fa fa-arrow-left w3-text-red">
            <span><b class="flow-margin-left">{{ $t('back') }}</b></span>
          </i>
        </button>
      </div>

    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import FlowSelect from "@/components/common/FlowSelect";
import TimePicker from "../../common/TimePicker";

export default {
  name: "NewVisit",
  components: {ContentHeader, FlowSelect, TimePicker},
  props: ['onClose', 'callbackAddVisit', 'saveVisitEnabled'],
  data() {
    return {
      newVisit: {time: ''},
      npFNameError: undefined,
      npLNameError: undefined,
      openTimePicker: false,
      timeError: undefined,
      languages: [],
      domainTypes: [],
      contacts: [],
      selectedLang: {},
      selectedDomain: {},
      selectedContact: {}
    }
  },
  mounted() {
    this.getAllActiveLanguages();
    this.getAllDomains();
    this.getAllContacts();
  },
  methods: {
    getAllContacts() {
      const app = this;
      app.contacts = [];
      app.$restClient.deskDetails.all().then(response => {
        let data = response.data;
        if (data.length > 0) {
          let con = [];
          data.forEach(function (d) {
            if (d.userId !== null) {
              con.push({
                id: d.userId,
                name: d.firstName + " " + d.lastName
              });
            }
          })

          let uniqueContacts = [...new Map(con.map(item => [item['id'], item])).values()];

          this.$helper.sortByKey(uniqueContacts, "name");
          uniqueContacts.unshift({
            id: null,
            name: null
          });
          app.contacts = uniqueContacts;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },
    getAllDomains() {
      const app = this;
      app.domainTypes = [];
      app.$restClient.domains.all().then(response => {
        let data = response.data;
        if (data.length > 0) {
          let dT = [];
          data.forEach(function (d) {
            dT.push(d.type);
          })
          this.$helper.sortByKey(dT, "name");
          app.domainTypes = dT;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },
    getAllActiveLanguages() {
      const app = this;
      app.languages = [];
      app.$restClient.languages.all().then(response => {
        let data = response.data;
        if (data.length > 0) {
          this.$helper.sortByKey(data, "name");
          app.languages = data;
          const nl = data.find(({name}) => name.toUpperCase() === 'Nederlands'.toUpperCase());
          app.newVisit.language = nl;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },
    timeNow() {
      return this.$helper.getTime(Date.now());
    },
    onChange(time) {
      this.newVisit.time = time;
    },
    addNewVisit() {
      this.npFNameError = undefined;
      this.npLNameError = undefined;

      if (!this.newVisit.firstname || !this.newVisit.firstname.trim()) {
        this.npFNameError = this.$t('INVALID_FIRST_NAME');
      }

      if (!this.newVisit.lastname || !this.newVisit.lastname.trim()) {
        this.npLNameError = this.$t('INVALID_LAST_NAME');
      }

      let visitTime = undefined;
      if (this.newVisit.time === '') {
        visitTime = this.timeNow();
      } else {
        visitTime = this.newVisit.time.val
      }

      if (this.npFNameError || this.npLNameError) return;

      this.newVisit.language = this.selectedLang;
      this.newVisit.domainType = this.selectedDomain;
      this.newVisit.contact = this.selectedContact;

      // let time = this.$helper.getDate(visitTime, "h:m");

      let visit = {
        "firstName": this.newVisit.firstname,
        "lastName": this.newVisit.lastname,
        "domainType": this.newVisit.domainType.id,
        "appointmentTime": visitTime,
        "languageId": this.newVisit.language.id,
        "comment": this.newVisit.comment
      }
      if (this.newVisit.contact.id !== null) {
        visit.contactId = this.newVisit.contact.id
        visit.contactName = this.newVisit.contact.name
      }

      this.callbackAddVisit(visit);

    },

    setSelectedDomain(option) {
      this.selectedDomain = option;
    },

    setSelectedLanguage(option) {
      this.selectedLang = option;
    },

    setSelectedContact(option) {
      this.selectedContact = option;
    }
  }
}
</script>