<template>
  <div>
    <div>
      <div class="w3-container flow-main-content w3-center w3-padding w3-ripple">
      </div>
      <div class="w3-container flow-card blue-top w3-animate-opacity flow-rooms-content">
        <loading v-bind:dspin="!contentLoaded"></loading>
        <table v-show="contentLoaded" class="w3-table w3-bordered flow-table">
          <thead class="flow-main-color">
          <th>{{ $t("name") }}</th>
          <th>{{ $t("is-default-room") }}</th>
          <th>{{ $t("route") }}</th>
          <th></th>
          <th>{{ $t("created-at-by") }}</th>
          <th>{{ $t("updated-at-by") }}</th>
          <th></th>
          <th></th>
          </thead>
          <tbody>
          <tr v-show="rooms.length === 0">
            <td class="w3-center flow-main-color" colspan="8">
              <b class="font-size-1pt25em">{{ $t("noWaitingRooms") }}</b>
            </td>
          </tr>
          </tbody>

          <tbody class="waiting-room-table">
          <tr v-for="room in rooms" v-bind:id="room.id" :key="room.id" class="no-hover w3-border-0">
            <td>
              <input v-model="room.name" class="w3-input w3-border w3-round-medium" type="text"
                     v-bind:placeholder="$t('name')"/>
              <span v-if="!room.name" class="w3-text-red">{{ $t("INVALID_NAME") }}</span>
            </td>
            <td class="qpp-text-align-center qpp-inline-buttons">
              <span> {{ room.default === true ? $t("yes") : $t("no") }} </span>
            </td>
            <td class="">
              <input v-model="room.route" class="w3-input w3-border w3-round-medium" maxlength="1"
                     size="3" type="text" v-bind:placeholder="$t('route')"/>
            </td>

            <td class="w3-input w-2pc qpp-room-route-field">
              <flow-select :callback="setSelectedColor"
                           :hasBackgroundColor=true
                           :items="colors"
                           :mainItem="room"
                           :preSelectedItem="getPreSelectedColor(room)"
                           :valueKey="''"
                           :valueKeyIndex="'colorCode'"
                           type="text">
              </flow-select>
            </td>
            <td class="qpp-inline-buttons">
              <span> {{ room.createdBy }} </span> <span class="w3-block"> ({{ room.createdAt }}) </span>
            </td>
            <td class="qpp-inline-buttons">
              <span> {{ room.updatedBy }}  </span> <span class="w3-block"> ({{ room.updatedAt }}) </span>
            </td>
            <td class="">
                <span class="btn flow-btn w3-padding-small w3-round-medium" v-if="!openNewForm"
                      v-bind:class="showSaveButton(room) ? 'cursor-pointer' : 'w3-disabled'"
                      @click.prevent="saveRoom(room)">
                  <i class="fa fa-check-circle w3-large flow-green-active-color"></i>
                  <span
                      class="flow-green-active-color flow-margin-left font-weight-bold w3-large">{{
                      $t('confirm')
                    }}</span>
                </span>
            </td>

            <td class="">
                <span class="btn flow-btn w3-padding-small w3-round-medium" v-if="!openNewForm"
                      v-bind:class="room.default === false ? 'cursor-pointer' : 'w3-disabled'"
                      @click.prevent="makeDefault(room)">
                  <span
                      class="flow-green-active-color flow-margin-left font-weight-bold w3-large">{{
                      $t('make-default-room')
                    }}</span>
                </span>
            </td>

            <td class="">
                <span class="btn flow-btn w3-padding-small w3-round-medium cursor-pointer" v-if="!openNewForm"
                      @click.prevent="deleteRoom(room)">
                  <i class="fa fa-trash w3-text-deep-orange"></i>
                  <span
                      class="w3-text-deep-orange flow-margin-left font-weight-bold w3-large">{{
                      $t('delete')
                    }}</span>
                </span>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/common/Loading";
import FlowSelect from "@/components/common/FlowSelect";
import colors from '../../../ColorOptions.json'

import {watch} from "vue";

export default {
  name: 'WaitingRoomsList',
  props: ['newRoomAdded','openNewForm'],
  components: {Loading, FlowSelect},

  data() {
    return {
      contentLoaded: false,
      rooms: [],
      isAdmin: true,
      masterCopy: [],
      colors: colors
    }
  },

  mounted() {
    watch(() => this.newRoomAdded, () => {
      this.getAllRooms();
    });
    this.getAllRooms();
  },

  methods: {

    getAllRooms() {
      const app = this;
      app.rooms = [];
      app.$restClient.waitingRooms.all().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.rooms = data;
          data.forEach(function (d) {
            app.manageMasterCopy(d);
          });
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    showSaveButton(_room) {
      if (!_room) {
        return false;
      }
      var mp = this.masterCopy[_room.id];
      if (!mp) {
        return false;
      }

      return (mp.name !== _room.name || mp.route !== _room.route || mp.color !== _room.color);
    },

    saveRoom(room) {
      if (!this.showSaveButton(room)) return;

      const app = this;
      app.$restClient.waitingRooms.update(room).then(response => {
        // eslint-disable-next-line no-unused-vars
        let data = response.data;
        app.getAllRooms();
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
      }).catch(err => {
        console.log(err);
        if (err && err.response.status === 400)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('waiting-room-name-unique')});
        else
          app.$notify.notifyError({title: this.$t("failed")});
      });
    },

    makeDefault(room) {
      const app = this;
      app.$restClient.waitingRooms.makeDefault(room).then(response => {
        // eslint-disable-next-line no-unused-vars
        let data = response.data;
        app.getAllRooms();
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed"), message: this.$t("waiting-room-unique")});
      });
    },

    setSelectedColor(color, _room) {
      _room.color = color.colorCode;
    },

    getPreSelectedColor(room) {
      return this.colors.filter((color) => {
        return color.colorCode === room.color
      })[0];
    },

    manageMasterCopy(_room) {
      this.masterCopy[_room.id] = {
        name: _room.name,
        route: _room.route,
        color: _room.color
      };
    },

    deleteRoom(room){
      const app = this;
      this.$restClient.waitingRooms.deleteWaitingRoom(room.id).then(() => {
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")})
        app.getAllRooms();
      }).catch(error => {
        console.log(error);
        if(error.response.status===423){
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t("waiting-room-has-instructions")})
        } else if (error.response.status===424) {
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t("waiting-room-has-desks")})
        } else if (error.response.status===425) {
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t("waiting-room-has-visits")})
        } else {
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t("failed-to-delete")})
        }
      })
    }

  }
}
</script>