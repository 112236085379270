<template>
  <div>
    <div>
      <div class="w3-container flow-main-content w3-center w3-padding w3-ripple">
      </div>
      <div class="w3-container flow-card blue-top w3-animate-opacity w-90pc flow-margin-left-5-pc">
        <loading v-bind:dspin="!contentLoaded"></loading>
        <table v-show="contentLoaded" class="w3-table w3-bordered flow-table">
          <thead class="flow-main-color">
          <span class="qpp-walkIns-container">
            <th class="qpp-walkIns-list-fields">
              {{ $t("walking-ins-domain") }}</th>
            <th class="qpp-walkIns-list-fields"> {{ $t("regime") }}</th>
            <th class="qpp-walkIns-list-fields">{{ $t("kiosk") }}</th>
            <th class="qpp-walkIns-list-fields" v-if="!openNewForm && !openEditForm">
              <span> {{ $t("register") }}</span>
            </th>
            <th class="qpp-walkIns-list-fields" v-if="!openNewForm && !openEditForm">{{ $t("period") }}</th>
            <th class="qpp-walkIns-list-fields w-25pc" style="padding: 0 15px;" v-if="!openNewForm && !openEditForm">
              <span> {{ $t("standard-text") }} </span> </th>
            <th class="qpp-walkIns-list-fields" v-if="!openNewForm && !openEditForm">
              <span>{{ $t("destination") }} </span> </th>
            <th class="qpp-walkIns-list-fields" v-if="!openNewForm && !openEditForm">
              <span>  {{ $t("direction") }} </span></th>
            </span>
          </thead>
          <tbody v-show="instructions.length === 0">
          <tr>
            <td class="w3-center flow-main-color" colspan="8">
              <b class="font-size-1pt25em">{{ $t("noInstructions") }}</b>
            </td>
          </tr>
          </tbody>

          <tbody class="">
          <span v-for="instruction in instructions" :key="instruction.id" class="qpp-walkIns-container">
          <tr @click="showEditForm(instruction)">
              <td class="qpp-walkIns-list-fields">
                {{ instruction.domain == null ? '' : instruction.domain.name }}
              </td>
              <td class="qpp-walkIns-list-fields">
                {{ instruction.regime == null ? '' : instruction.regime.name }}</td>
              <td class="qpp-walkIns-list-fields ">
                {{ instruction.kiosk != null ? instruction.kiosk.username : '' }}
              </td>
              <td class="qpp-walkIns-list-fields" v-if="!openNewForm && !openEditForm">
                <input :checked="instruction.registerAppointment" class="w3-check"
                       disabled="disabled" type="checkbox"/>
               </td>
            <td class="qpp-walkIns-list-fields" v-if="!openNewForm && !openEditForm">
              {{instruction.validFrom + " - "}} <br/> {{instruction.validUntil}}
            </td>
            <td style="padding: 0 15px;" class="qpp-walkIns-list-fields" :class='!openNewForm && !openEditForm ?  "w-25pc" : ""'
                v-if="!openNewForm && !openEditForm">
              <span class="w3-tooltip">
                <span v-if="instruction.locale == null"> </span>
                <span v-else>{{ truncateMessage(instruction.locale.value) }} </span>
                <span class="w3-text w3-tag standard-text-hover"
                      v-if="instruction.locale != null && instruction.locale.value.length > 21">
                {{instruction.locale.value}} </span>
              </span>
              </td>
              <td class="qpp-walkIns-list-fields" v-if="!openNewForm && !openEditForm">
                {{ instruction.waitingRoom == null ? '' : instruction.waitingRoom.name }}
              </td>

              <td class="qpp-walkIns-list-fields qpp-walkIns-direction" v-if="!openNewForm && !openEditForm">
                <div class="qpp-walkIns-direction">
                  <img v-if="instruction.arrowAngle != null" :src="require('@/assets/images/arrow.png')"
                       :style="{transform: 'rotate('+ instruction.arrowAngle +'deg)'}"/></div>
            </td>
            </tr>
            </span>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/common/Loading";

export default {
  name: 'WalkingInstructionList',
  props: ['openNewForm','openEditForm','instructions','contentLoaded'],
  components: {Loading},
  emits: ['openDetails'],

  data() {
    return {
      currentInstruction: undefined,
      directions: [{name: 'top'}, {name: 'top right'}, {name: 'right'}, {name: 'bottom right'},
        {name: 'bottom'}, {name: 'bottom left'}, {name: 'left'}, {name: 'top left'}],
      angles: [0, 45, 90, 135, 180, -135, -90, -45, null]
    }
  },
  methods: {
    showEditForm(ins) {
      this.$emit('openDetails', ins);
    },

    truncateMessage(message) {
      if (message.length > 21)
        return message.substring(0, 21) + "(...)";
      return message;
    }

  }
}
</script>