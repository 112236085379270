<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('waiting-rooms')"></content-header>
        <div class="w3-right w3-margin-right flow-margin-top">
          <i class="fa fa-plus flow-main-color w3-xlarge cursor-pointer w3-margin-right" @click="openNewForm = true"></i>
        </div>
      </div>
    </div>


    <div class="content-sidebars sidebars-70-30 ">
      <div v-if="openNewForm" class="w3-container content-sidebar-right">
        <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="openNewForm = false"></i>
        <new-waiting-room :onClose="() => {openNewForm = false}" @addedNewRoom="updateRoomsList($event)"></new-waiting-room>
      </div>

      <div v-bind:class="openNewForm ? 'content-sidebar-left' : ''">
        <waiting-rooms-list :newRoomAdded="newRoomAdded" :openNewForm="openNewForm"></waiting-rooms-list>
      </div>
    </div>

  </div></template>

<script>
import ContentHeader from "../../common/ContentHeader";
import WaitingRoomsList from "./WaitingRoomsList";
import NewWaitingRoom from "./NewWaitingRoom";

export default {
  name: "WaitingRooms",
  components: {NewWaitingRoom, WaitingRoomsList, ContentHeader},
  data() {
    return {
      openNewForm: false,
      newRoomAdded: false
    }
  },
  methods: {
    updateRoomsList (desk) {
      this.newRoomAdded = desk;
    }
  }
}
</script>